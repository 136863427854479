/* stylelint-disable declaration-no-important, scss/selector-no-redundant-nesting-selector */
.example:not(:first-child) {
  margin-top: 1.5rem;
}
.example .tab-content {
  background-color: var(--cui-tertiary-bg);
}
.example + p {
  margin-top: 1.5rem;
}
.example .preview + p {
  margin-top: 2rem;
}
.example .preview > .form-control + .form-control {
  margin-top: 0.5rem;
}
.example .preview > .nav + .nav,
.example .preview > .alert + .alert,
.example .preview > .navbar + .navbar,
.example .preview > .progress + .progress {
  margin-top: 1rem;
}
.example .preview > .dropdown-menu {
  position: static;
  display: block;
}
.example .preview > :last-child {
  margin-bottom: 0;
}
.example .preview > svg + svg,
.example .preview > img + img {
  margin-left: 0.5rem;
}
.example .preview .col > .btn,
.example .preview .col-auto > .btn,
.example .preview > .btn,
.example .preview > .btn-group {
  margin: 0.25rem 0.125rem;
}
.example .preview > .btn-toolbar + .btn-toolbar {
  margin-top: 0.5rem;
}
.example .preview > .list-group {
  max-width: 400px;
}
.example .preview > [class*=list-group-horizontal] {
  max-width: 100%;
}
.example .preview .fixed-top,
.example .preview .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}
.example .preview .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}
@media (min-width: 576px) {
  .example .preview .fixed-top,
  .example .preview .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .example .preview .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}
.example .preview .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.example .preview .docs-example-modal .modal {
  position: static;
  display: block;
}

.dark-theme .example .tab-content,
[data-coreui-theme=dark] .example .tab-content {
  background-color: var(--cui-secondary-bg) !important;
}

/*# sourceMappingURL=examples.css.map */
